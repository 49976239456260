import React, { useEffect, useContext, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link, useParams } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import Button from "@mui/material/Button";
import { ProductContext } from "./Component/context/AppContext";

function Footer() {
  const [allproduct2, setAllProduct2] = useState();
  const { setSelectedProduct, politicalData, headerIcon, FooerService } =
    useContext(ProductContext);
  const socialList = headerIcon?.response?.records;
  console.log(FooerService, "FooerService New");

  const filteredPolitical = politicalData?.response?.records.filter((item) =>
    item.find(
      (field) =>
        field.name === "Category" && field.value === "Political Report Card"
    )
  );

  const filteredFooerService = FooerService?.response?.records.filter((item) =>
    item.find((field) => field.name === "Privacy Policy")
  );
  console.log(filteredFooerService, "filteredFooerService");

  const filteredOurHeroes = politicalData?.response?.records.filter((item) =>
    item.find(
      (field) => field.name === "Category" && field.value === "Our Heroes"
    )
  );

  const handleItemClick = (ChiefMinisterCM) => {
    setSelectedProduct(ChiefMinisterCM); // Save the selected product details in the context state.
    localStorage.setItem("SingleData", JSON.stringify(ChiefMinisterCM));
  };
  const handleServiceClick = (name) => {
    localStorage.setItem("SingleData", JSON.stringify(name));
  };
  // const sortPoliticalData = (templateID) => {
  //   let dataName = [...filteredPolitical];
  //   if (dataName?.length > 0) {
  //     let resultFilterName = dataName.filter(
  //       (value) => value.templateID === templateID
  //     );
  //     setAllProduct2(resultFilterName);
  //     localStorage.setItem("SingleData", JSON.stringify(resultFilterName));
  //   }
  // };
  return (
    <footer className="main-footer">
      <div className="pattern-layer" />
      <div className="auto-container">
        {/* Widgets Section */}
        <div className="widgets-section">
          <div className="row clearfix">
            {/* Big Column */}
            <div className="big-column col-lg-12 col-md-12 col-sm-12">
              <div className="row clearfix">
                {/*Footer Column*/}
                <div className="footer-column col-lg-3 col-md-3 col-sm-12">
                  <div className="footer-widget links-widget">
                    <h5>SERVICES</h5>
                    <ul className="list-link online-ul1">
                      {FooerService?.response?.records[0]?.map(
                        (service1, i) => {
                          if (i >= 3) {
                            return (
                              <li>
                                <Link
                                  to={`../data/${encodeURIComponent(
                                    service1?.name.replace(/ /g, "-")
                                  )}`}
                                  onClick={() => handleServiceClick(service1)}
                                >
                                  {service1?.name}
                                </Link>
                              </li>
                            );
                          }
                        }
                      )}
                      <li>
                      <a href="https://projects.erpthemes.com" target="_blank">
                      CRM Login
                    </a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/*Footer Column*/}
                <div className="footer-column col-lg-9 col-md-9 col-sm-12">
                  <div className="footer-widget links-widget">
                    <h5>{filteredPolitical?.[0][3]?.value}</h5>
                    <ul className="list-link list-link-5">
                      {filteredPolitical
                        ?.sort((a, b) =>
                          a[11]?.value.localeCompare(b[11]?.value)
                        )
                        .map((ChiefMinisterCM, index) => {
                          return (
                            <li>
                              <Link
                                to="../political-report-card"
                                onClick={() => handleItemClick(ChiefMinisterCM)}
                                // target="_blank"
                              >
                                {ChiefMinisterCM?.[11]?.value}
                              </Link>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                  <div className="footer-widget links-widget">
                    <h5>{filteredOurHeroes?.[0][3]?.value}</h5>
                    <ul className="list-link list-link-5">
                      {filteredOurHeroes
                        ?.sort((a, b) =>
                          a[11]?.value.localeCompare(b[11]?.value)
                        )
                        .map((ChiefMinisterCM, index) => {
                          {
                            /* {filteredOurHeroes?.map((ChiefMinisterCM,index)=>{ */
                          }
                          return (
                            <li>
                              <Link
                                to="../our-heroes"
                                onClick={() => handleItemClick(ChiefMinisterCM)}
                                // target="_blank"
                              >
                                {ChiefMinisterCM?.[11]?.value}
                              </Link>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>
                {/* col-md-12 */}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 col-xs-12">
              <p className="rajnitti-11">@ Rajnitti.com</p>
            </div>
            <div className="col-sm-6 col-xs-12">
              <ul className="footer-2">
                <li>
                  <a href={socialList?.[6][4].value} target="_blank">
                    <i className="fa fa-youtube" />
                  </a>
                </li>
                <li>
                  <a href={socialList?.[5][4].value} target="_blank">
                    <i className="fa fa-twitter" />
                  </a>
                </li>
                <li>
                  <a href={socialList?.[4][4].value} target="_blank">
                    <i className="fa fa-linkedin" />
                  </a>
                </li>
                <li>
                  <a href={socialList?.[3][4].value} target="_blank">
                    <i className="fa fa-facebook-f" />
                  </a>
                </li>
                <li>
                  <a href={socialList?.[2][4].value} target="_blank">
                    <i className="fa fa-instagram" />
                  </a>
                </li>
                <li>
                  <a href={socialList?.[1][4].value} target="_blank">
                    <i className="fa fa-pinterest" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
