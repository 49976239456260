import React, { useEffect, useContext, useState } from "react";
import Header from '../Header'
import Footer from '../Footer'
import { FallbackImage } from './FallbackImage';
import { Link, useParams } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import { ProductContext } from './context/AppContext';
import Banner from "./Banner";

function SurveyForm(props) {
  const { handleClickOpen } = props;

  const { Formfield } = useContext(ProductContext);


  const [updateform, setUpdateForm] = useState([]);

  const [BoothNO, setBoothNO] = useState();
  const [Name, setName] = useState();
  const [MobileNo, setMobileNo] = useState();

  const [Gender, setGender] = useState();
  const [Age, setAge] = useState();
  const [Occupation, setOccupation] = useState();
  const [PartyName, setPartyName] = useState();
  const [Mukhiya, setMukhiya] = useState();
  const [MukhiyaPercentOfHappy, setMukhiyaPercentOfHappy] = useState();

  const [Parshad, setParshad] = useState();
  const [ParshadPercentOfHappy, setParshadPercentOfHappy] = useState();
  const [MLA, setMLA] = useState();
  const [MLAPercentOfHappy, setMLAPercentOfHappy] = useState();
  const [CM, setCM] = useState();
  const [CmPercentOfHappy, setCmPercentOfHappy] = useState();
  const [AnyProblem, setAnyProblem] = useState();


  const signUPpara = {
    "boothno": BoothNO,
    "name": Name,
    "mobile": Number(MobileNo),
    "gender": Gender,
    "age": Age,
    "occupation": Occupation,
    "partyname": PartyName,
    "mukhiya": Mukhiya,
    "mukhiyapercentofhappy": MukhiyaPercentOfHappy,
    "parshad": Parshad,
    "parshadpercentofhappy": ParshadPercentOfHappy,
    "mla": MLA,
    "mlapercentofhappy": MLAPercentOfHappy,
    "CM": CM,
    "cmpercentofhappy": CmPercentOfHappy,
    "anyproblem": AnyProblem,
    "domain": "rajnitti.com"
  }
  function UpdateFormAPI() {
    fetch('https://projects.erpthemes.com/api/dynamic/addRecordsDynamic?tempID=28&tempName=rajnitti_survey_form', {
      method: "POST",
      headers: {
        "authorization": "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NzJhZjYwMDlmNjA4NDEwZmI1NDA3MDIiLCJzZXNzaW9uIjoiNjcyYWY2MDA5ZjYwODQxMGZiNTQwNzA0IiwibmFtZSI6InJham5pdHRpIiwiZW1haWwiOiJuZWVkaXRnZXRpdDAxQGdtYWlsLmNvbSIsInVzZXJUeXBlIjoidXNlciIsInVzZXJJZCI6IjY3MmFmNjAwOWY2MDg0MTBmYjU0MDcwMiIsImlhdCI6MTczMDg2ODczNiwiZXhwIjoxODg4NjU2NzM2fQ.hD2669WhbEg7zs5sUGctJDqoqVkcK6xt6NyefS55fPHF5Mn5wIcubByFthlOmkzXTj5zqlXcBF9GjPBFCwtxmGX9e-50o7CU20Tnu9ph86xZ-Q5PY8gwVokm5DbCFKbFg2nKBOCJxo-QL3ZHIMGvDmjc5xiJuFbqCH7J7Tc0EkuMyfdWGFJ7kgfV6gQ9HrD4Q7DGZB5ug9zLBFZzEXaAS-A43YLBya0e_jcyQpn5UoKyUZ8eDn4C5KnjmZBb3bIc35H5p_IKWYO0hyjeTRH7zPRZxGMAgE9GlxxKNTfxUhPI_ik8to4ayKQiMeAYa4hCgkotl2G_yPbm3vhyNZy7Xg",
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(signUPpara),
    }).then((result) => {
      result.json().then((resp) => {
        console.log(JSON.stringify(resp), "resp");
        alert(JSON.stringify(resp.resp.message));
        handleClickOpen();

      });
    });
  }

  return (
    <div>
      <Header />
      <Banner />

      <div className="bbb_viewed footer-1 footer-55 aboutus-2">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <h2 className='aboutus-1'>Survey Form</h2>
            </div>

            <div className="col-sm-12">

              <div className="row">
                <hr />

                <div className="col-sm-6 col-md-4">
                  <label htmlFor="email">
                    <b className='aboutus-1'>Booth NO</b>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Booth NO."
                    id="email"
                    name="BoothNO"
                    value={BoothNO}
                    onChange={(e) => setBoothNO(e.target.value)}
                    required
                  />
                </div>

                <div className="col-sm-6 col-md-4">
                  <label htmlFor="email">
                    <b className='aboutus-1'>Name</b>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Name"
                    id="email"
                    name="Name"
                    value={Name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </div>
                <div className="col-sm-6 col-md-4">
                  <label htmlFor="email">
                    <b className='aboutus-1'>Mobile Number</b>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Mobile No."
                    id="email"
                    name="MobileNo"
                    value={MobileNo}
                    onChange={(e) => setMobileNo(e.target.value)}
                    required
                  />
                </div>
                <div className="col-sm-6 col-md-4">
                  <label htmlFor="email">
                    <b className='aboutus-1'>Gender</b>
                  </label>
                  <select id="txtProductCategory" name="Gender" value={Gender}
                    onChange={(e) => setGender(e.target.value)} >
                    <option value="">--Select Gender--</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </select>
                </div>


                <div className="col-sm-6 col-md-4">
                  <label htmlFor="email">
                    <b className='aboutus-1'>Age</b>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Age"
                    id="email"
                    name="Age"
                    value={Age}
                    onChange={(e) => setAge(e.target.value)}
                    required
                  />
                </div>
                <div className="col-sm-6 col-md-4">
                  <label htmlFor="email">
                    <b className='aboutus-1'>Occupation</b>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Occupation"
                    id="email"
                    name="Occupation"
                    value={Occupation}
                    onChange={(e) => setOccupation(e.target.value)}
                    required
                  />
                </div>
                <div className="col-sm-6 col-md-4">
                  <label htmlFor="email">
                    <b className='aboutus-1'>Party Name</b>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Party Name"
                    id="email"
                    name="PartyName"
                    value={PartyName}
                    onChange={(e) => setPartyName(e.target.value)}
                    required
                  />
                </div>
                <div className="col-sm-6 col-md-4">
                  <label htmlFor="email">
                    <b className='aboutus-1'>Mukhiya</b>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Mukhiya"
                    id="email"
                    name="Mukhiya"
                    value={Mukhiya}
                    onChange={(e) => setMukhiya(e.target.value)}
                    required
                  />
                </div>
                <div className="col-sm-6 col-md-4">
                  <label htmlFor="email">
                    <b className='aboutus-1'>Mukhiya Percent Of Happy	</b>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Mukhiya Percent Of Happy	"
                    id="email"
                    name="MukhiyaPercentOfHappy"
                    value={MukhiyaPercentOfHappy}
                    onChange={(e) => setMukhiyaPercentOfHappy(e.target.value)}
                    required
                  />
                </div>
                <div className="col-sm-6 col-md-4">
                  <label htmlFor="email">
                    <b className='aboutus-1'>Parshad</b>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Parshad"
                    id="email"
                    name="Parshad"
                    value={Parshad}
                    onChange={(e) => setParshad(e.target.value)}
                    required
                  />
                </div>
                <div className="col-sm-6 col-md-4">
                  <label htmlFor="email">
                    <b className='aboutus-1'>Parshad Percent Of Happy	</b>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Parshad Percent Of Happy	"
                    id="email"
                    name="ParshadPercentOfHappy"
                    value={ParshadPercentOfHappy}
                    onChange={(e) => setParshadPercentOfHappy(e.target.value)}
                    required
                  />
                </div>
                <div className="col-sm-6 col-md-4">
                  <label htmlFor="email">
                    <b className='aboutus-1'>MLA</b>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter MLA"
                    id="email"
                    name="MLA"
                    value={MLA}
                    onChange={(e) => setMLA(e.target.value)}
                    required
                  />
                </div>

                <div className="col-sm-6 col-md-4">
                  <label htmlFor="email">
                    <b className='aboutus-1'>MLA Percent Of Happy	</b>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter MLA Percent Of Happy	"
                    id="email"
                    name="MLAPercentOfHappy"
                    value={MLAPercentOfHappy}
                    onChange={(e) => setMLAPercentOfHappy(e.target.value)}
                    required
                  />
                </div>
                <div className="col-sm-6 col-md-4">
                  <label htmlFor="email">
                    <b className='aboutus-1'>Cheif Minister (CM)</b>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Cheif Minister (CM)"
                    id="email"
                    name="CM"
                    value={CM}
                    onChange={(e) => setCM(e.target.value)}
                    required
                  />
                </div>
                <div className="col-sm-6 col-md-4">
                  <label htmlFor="email">
                    <b className='aboutus-1'>Cm Percent Of Happy</b>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Cm Percent Of Happy"
                    id="email"
                    name="CmPercentOfHappy"
                    value={CmPercentOfHappy}
                    onChange={(e) => setCmPercentOfHappy(e.target.value)}
                    required
                  />
                </div>
                <div className="col-sm-6 col-md-4">
                  <label htmlFor="email">
                    <b className='aboutus-1'>Any Problem</b>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Any Problem"
                    id="email"
                    name="AnyProblem"
                    value={AnyProblem}
                    onChange={(e) => setAnyProblem(e.target.value)}
                    required
                  />
                </div>

                <hr />
                {/* <button className="registerbtn" onClick={UpdateFormAPI}>
              Submit
            </button> */}

                {AnyProblem?.length > 0 ? (
                  <button className="registerbtn" onClick={UpdateFormAPI}>
                    Submit
                  </button>
                ) : (
                  <button className="registerbtn">
                    Submit
                  </button>
                )
                }


              </div>

            </div>

          </div>
        </div>
      </div>

      <Footer />
    </div>
  )
}


export default SurveyForm
