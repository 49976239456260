import React, { useState, useContext, useEffect } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { FallbackImage } from "./FallbackImage";
import { Link, useParams } from "react-router-dom";
import Banner from "./Banner";
import Form from "react-bootstrap/Form";

function ContactUs(props) {
  const { handleClickOpen } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [Name, setName] = useState();
  const [MobileNo, setMobileNo] = useState();
  const [Email, setEmail] = useState();
  const [Message, setMessage] = useState();
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const regex = /^[0-9]*$/;
  const [error, setError] = useState("");
  const handleChange = (e) => {
    const value = e.target.value.replace(/[^0-9]/g, "");
    setMobileNo(value);
  };
  const fun_Contact = async () => {
    if (Name === undefined || Name === "") {
      alert("Please enter name.");
      return;
    } else if (MobileNo === undefined || MobileNo === "") {
      alert('Please enter mobile no."');
      return;
    } else if (MobileNo.length != 10) {
      alert("Please enter a valid 10-digit mobile number"); // Set error message if invalid
      return;
    } else if (Email === undefined || Email === "") {
      alert("Please enter email id.");
      return;
    } else if (!emailRegex.test(Email)) {
      alert("Please enter valid email id.");
      return;
    } else if (Message === undefined || Message === "") {
      alert('Please enter your message"');
      return;
    } else {
      try {
        const response = await fetch(
          "https://projects.erpthemes.com/api/dynamic/addRecordsDynamic?tempID=37&tempName=rajnitti_contact_us",
          {
            method: "POST",
            headers: {
              "authorization": "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NzJhZjYwMDlmNjA4NDEwZmI1NDA3MDIiLCJzZXNzaW9uIjoiNjcyYWY2MDA5ZjYwODQxMGZiNTQwNzA0IiwibmFtZSI6InJham5pdHRpIiwiZW1haWwiOiJuZWVkaXRnZXRpdDAxQGdtYWlsLmNvbSIsInVzZXJUeXBlIjoidXNlciIsInVzZXJJZCI6IjY3MmFmNjAwOWY2MDg0MTBmYjU0MDcwMiIsImlhdCI6MTczMDg2ODczNiwiZXhwIjoxODg4NjU2NzM2fQ.hD2669WhbEg7zs5sUGctJDqoqVkcK6xt6NyefS55fPHF5Mn5wIcubByFthlOmkzXTj5zqlXcBF9GjPBFCwtxmGX9e-50o7CU20Tnu9ph86xZ-Q5PY8gwVokm5DbCFKbFg2nKBOCJxo-QL3ZHIMGvDmjc5xiJuFbqCH7J7Tc0EkuMyfdWGFJ7kgfV6gQ9HrD4Q7DGZB5ug9zLBFZzEXaAS-A43YLBya0e_jcyQpn5UoKyUZ8eDn4C5KnjmZBb3bIc35H5p_IKWYO0hyjeTRH7zPRZxGMAgE9GlxxKNTfxUhPI_ik8to4ayKQiMeAYa4hCgkotl2G_yPbm3vhyNZy7Xg",
              Accept: "application/json, text/plain",
              "Content-Type": "application/json;charset=UTF-8",
            },
            body: JSON.stringify({
              query: Message,
              name: Name,
              email: Email,
              mobile: Number(MobileNo),
              domain: "rajnitti.com",
            }),
          }
        );

        const data = await response.json();
        alert(data.resp.message);
        console.log("Success:", data);
      } catch (error) {
        alert(error?.Message);
        console.error("Error:", error);
      }
    }
  };

  return (
    <div>
      <Header />
      <Banner />
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open responsive dialog
      </Button> */}
      <div className="bbb_viewed footer-1 footer-55 aboutus-2">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <h2 className="aboutus-1">Contact Us</h2>
            </div>

            <div className="row">
              <hr />

              <div className="col-sm-6 col-md-4">
                <label htmlFor="name">
                  <b className="aboutus-1">Name</b>
                </label>
                <input
                  type="text"
                  placeholder="Enter Name"
                  id="name"
                  name="Name"
                  value={Name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
              <div className="col-sm-6 col-md-4">
                <label htmlFor="mobile">
                  <b className="aboutus-1">Mobile Number</b>
                </label>
                <input
                  type="text"
                  placeholder="Enter Mobile No."
                  id="mobile"
                  name="MobileNo"
                  value={MobileNo}
                  //onChange={(e) => setMobileNo(e.target.value)}
                  onChange={handleChange}
                  maxLength={10}
                />
                {error && <p style={{ color: "red" }}>{error}</p>}
              </div>

              <div className="col-sm-6 col-md-4">
                <label htmlFor="email">
                  <b className="aboutus-1">Email</b>
                </label>
                <input
                  type="text"
                  placeholder="Enter Email"
                  required
                  id="email"
                  name="email"
                  value={Email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div className="col-sm-12 col-md-12">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>
                    <b className="aboutus-1">Message</b>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Enter Message"
                    value={Message}
                    onChange={(e) => setMessage(e.target.value)}
                    required
                  />
                </Form.Group>
              </div>

              <hr />

              <button className="registerbtn" onClick={() => fun_Contact()}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default ContactUs;
