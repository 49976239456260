import React, { useState, useContext, useEffect } from 'react'
import Header from '../Header'
import Footer from '../Footer'
import { FallbackImage } from './FallbackImage';
import { Link, useParams } from "react-router-dom";
import Banner from './Banner';
import Form from 'react-bootstrap/Form';

function FeedbackForm(props) {
  const { handleClickOpen } = props;

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const [updateform, setUpdateForm] = useState([]);

  const [Name, setName] = useState();
  const [MobileNo, setMobileNo] = useState();
  const [Email, setEmail] = useState();
  const [Message, setMessage] = useState();
  const [photo, setPhoto] = useState();
  const [selectedState, setSelectedState] = useState();
  const [selectedConstituency, setSelectedConstituency] = useState();

  const signUPpara = {

    "name": Name,
    "mobile": Number(MobileNo),   
    "photo": photo,
    "state": selectedState,
    "constituency": selectedConstituency,
    "query": Message,
    "domain": "rajnitti.com"
  }
  function UpdateFormAPI() {
    fetch('https://projects.erpthemes.com/api/dynamic/addRecordsDynamic?tempID=38&tempName=rajnitti_feedback_form', {
      method: "POST",
      headers: {
        "authorization": "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NzJhZjYwMDlmNjA4NDEwZmI1NDA3MDIiLCJzZXNzaW9uIjoiNjcyYWY2MDA5ZjYwODQxMGZiNTQwNzA0IiwibmFtZSI6InJham5pdHRpIiwiZW1haWwiOiJuZWVkaXRnZXRpdDAxQGdtYWlsLmNvbSIsInVzZXJUeXBlIjoidXNlciIsInVzZXJJZCI6IjY3MmFmNjAwOWY2MDg0MTBmYjU0MDcwMiIsImlhdCI6MTczMDg2ODczNiwiZXhwIjoxODg4NjU2NzM2fQ.hD2669WhbEg7zs5sUGctJDqoqVkcK6xt6NyefS55fPHF5Mn5wIcubByFthlOmkzXTj5zqlXcBF9GjPBFCwtxmGX9e-50o7CU20Tnu9ph86xZ-Q5PY8gwVokm5DbCFKbFg2nKBOCJxo-QL3ZHIMGvDmjc5xiJuFbqCH7J7Tc0EkuMyfdWGFJ7kgfV6gQ9HrD4Q7DGZB5ug9zLBFZzEXaAS-A43YLBya0e_jcyQpn5UoKyUZ8eDn4C5KnjmZBb3bIc35H5p_IKWYO0hyjeTRH7zPRZxGMAgE9GlxxKNTfxUhPI_ik8to4ayKQiMeAYa4hCgkotl2G_yPbm3vhyNZy7Xg",
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(signUPpara),
    }).then((result) => {
      result.json().then((resp) => {
        alert(JSON.stringify(resp.resp.message));
        handleClickOpen();
      });
    });
  }
  const productdata = JSON.parse(localStorage.getItem("productdata"));

  const filteredDataMLA = productdata?.response?.records.filter((item) =>
    item.find((field) => field.name === "Category" && field.value === "MLA")
  );
  const uniqueStateNames = new Set();
  const handleClick = (stateName) => {
    if (selectedState === stateName) {
      // If the same state name is clicked again, hide the data

      setSelectedState(null);
    } else {
      // Otherwise, show the data for the clicked state
      setSelectedState(stateName);
      localStorage.setItem("AllData2", JSON.stringify(stateName));
    }
  };

  const FilterConstituency = filteredDataMLA?.filter((item) =>
    item.find(
      (field) => field.name === "State" && field.value === selectedState
    )
  );

  return (
    <div>
      <Header />
      <Banner />
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open responsive dialog
      </Button> */}
      <div className="bbb_viewed footer-1 footer-55 aboutus-2">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <h2 className='aboutus-1'>Problem / Achievement of your Constituency</h2>
            </div>


            <div className="row">
              <hr />

              <div className="col-sm-6 col-md-4">
                <label htmlFor="email">
                  <b className='aboutus-1'>Name</b>
                </label>
                <input
                  type="text"
                  placeholder="Enter Name"
                  id="email"
                  name="Name"
                  value={Name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
              <div className="col-sm-6 col-md-4">
                <label htmlFor="email">
                  <b className='aboutus-1'>Mobile Number</b>
                </label>
                <input
                  type="text"
                  placeholder="Enter Mobile No."
                  id="email"
                  name="MobileNo"
                  value={MobileNo}
                  onChange={(e) => setMobileNo(e.target.value)}
                  required
                />
              </div>



              <div className="col-sm-6 col-md-4">
                <label htmlFor="email">
                  <b className='aboutus-1'>Problem / Achievement</b>
                </label>
                <input
                  type="text"
                  placeholder="Enter Problem / Achievement"
                  required
                  id="email"
                  name="Age"
                  value={Email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="col-sm-6 col-md-4">
                <label htmlFor="email">
                  <b className='aboutus-1'>Photo</b>
                </label>
                <input
                  type="file"
                  placeholder="Enter Problem / Achievement"
                  required
                  id="email"
                  className='photo11'
                  name="Age"
                  value={photo}
                  onChange={(e) => setPhoto(e.target.value)}
                />
              </div>

              <div className="col-sm-6 col-md-4">
                <label htmlFor="email">
                  <b className='aboutus-1'>State</b>
                </label>
                <select name="productCategory" id="txtProductCategory"
                  value={selectedState}
                  onChange={(e) => {
                    const selectedStateName = e.target.value;
                    setSelectedState(selectedStateName);
                  }}
                >
                  <option value="">--Select State--</option>
                  {filteredDataMLA?.map((ChiefMinisterCM, index) => {
                    const stateNameField = ChiefMinisterCM.find(
                      (field) => field.name === "State"
                    );
                    const stateName = stateNameField
                      ? stateNameField.value
                      : "";

                    if (!uniqueStateNames.has(stateName)) {
                      uniqueStateNames.add(stateName);
                      return (
                        <option key={index} value={stateName}>{stateName}
                        </option>
                      );
                    }
                    return null;
                  })}
                </select>
              </div>

              <div className="col-sm-4">
                <label htmlFor="psw">
                  <b>Constituency</b>
                </label>
                <select
                  value={selectedConstituency}
                  onChange={(e) => setSelectedConstituency(e.target.value)}
                >
                  <option value="">--Select Constituency--</option>
                  {FilterConstituency?.map((productitem12, i) => {
                    return (
                      <option key={i} value={productitem12?.[24]?.value}>
                        {productitem12?.[24]?.value}
                      </option>
                    );
                  })}
                </select>
              </div>


              <div className="col-sm-6 col-md-4">
                <Form.Label><b className='aboutus-1'>Remarks</b></Form.Label>
                <input
                  type="text"
                  placeholder="Enter Remarks"
                  required
                  id="email"
                  name="Age"
                  value={Message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>


              <hr />
              {/* {Message?.length > 0 ? ( */}
              <button className="registerbtn" onClick={UpdateFormAPI}>
                Submit
              </button>
              {/* ) : (
<button className="registerbtn">
              Submit
            </button>
            )
} */}



            </div>



          </div>
        </div>
      </div>


      <Footer />
    </div>
  )
}
export default FeedbackForm
